<template>
  <g>
    <svg:style>
      .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
      }

      .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-8 {
        fill: url(#inox-gradient-6);
      }

      .cls-9 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 70.81"
                    :y1="doorTopHeight + 245.84"
                    :x2="doorLeftWidth + 70.81"
                    :y2="doorTopHeight + 24.97"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth + 104.04"
                    :y1="doorTopHeight + 152.88"
                    :x2="doorLeftWidth + 104.04"
                    :y2="doorTopHeight + 147.93"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-3"
                    data-name="inox"
                    :x1="doorLeftWidth + 104.04"
                    :y1="doorTopHeight + 142.98"
                    :x2="doorLeftWidth + 104.04"
                    :y2="doorTopHeight + 138.03"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-4"
                    data-name="inox"
                    :x1="doorLeftWidth + 104.04"
                    :y1="doorTopHeight + 162.77"
                    :x2="doorLeftWidth + 104.04"
                    :y2="doorTopHeight + 157.83"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-5"
                    data-name="inox"
                    :x1="doorLeftWidth + 37.59"
                    :y1="doorTopHeight + 152.87"
                    :x2="doorLeftWidth + 37.59"
                    :y2="doorTopHeight + 147.9"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-6"
                    data-name="inox"
                    :x1="doorLeftWidth + 37.6"
                    :y1="doorTopHeight + 142.97"
                    :x2="doorLeftWidth + 37.6"
                    :y2="doorTopHeight + 138.01"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-7"
                    data-name="inox"
                    :x1="doorLeftWidth + 37.58"
                    :y1="doorTopHeight + 162.76"
                    :x2="doorLeftWidth + 37.58"
                    :y2="doorTopHeight + 157.8"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.29"
                    :y1="doorTopHeight + 152.41"
                    :x2="doorLeftWidth + 16.95"
                    :y2="doorTopHeight + 152.41"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth + 70.81"
                    :y1="doorTopHeight + 240.89"
                    :x2="doorLeftWidth + 70.81"
                    :y2="doorTopHeight + 59.92"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V08">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`
                  ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 54.98}
                  ${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 54.98}
                  ${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 164.54}
                  ${doorLeftWidth1 + 53.14} ${doorTopHeight1 + 245.84}
                  ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 245.84}
                  ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 164.54}
                  ${doorLeftWidth1 + 88.49} ${doorTopHeight1 + 54.98}
                 `"/>

      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`
                  ${inoxX2} ${doorTopHeight1 + 147.93}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 147.93}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 152.88}
                  ${inoxX2} ${doorTopHeight1 + 152.88}
                  ${inoxX2} ${doorTopHeight1 + 147.93}
                 `"/>

      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`
                  ${inoxX2} ${doorTopHeight1 + 138.03}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 138.03}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 142.98}
                  ${inoxX2} ${doorTopHeight1 + 142.98}
                  ${inoxX2} ${doorTopHeight1 + 138.03}
                `"/>

      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 162.77}
                  ${inoxX2} ${doorTopHeight1 + 162.77}
                  ${inoxX2} ${doorTopHeight1 + 157.83}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 157.83}
                  ${doorLeftWidth1 + 89.91} ${doorTopHeight1 + 162.77}
                 `"/>

      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 147.92}
                  ${inoxX1} ${doorTopHeight1 + 147.9}
                  ${inoxX1} ${doorTopHeight1 + 152.85}
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 152.87}
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 147.92}`"/>

      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 138.02}
                  ${inoxX1} ${doorTopHeight1 + 138.01}
                  ${inoxX1} ${doorTopHeight1 + 142.96}
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 142.97}
                  ${doorLeftWidth1 + 51.73} ${doorTopHeight1 + 138.02}
                `"/>

      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-9"
                :points="`
                  ${inoxX1} ${doorTopHeight1 + 162.75}
                  ${doorLeftWidth1 + 51.72} ${doorTopHeight1 + 162.76}
                  ${doorLeftWidth1 + 51.72} ${doorTopHeight1 + 157.82}
                  ${inoxX1} ${doorTopHeight1 + 157.8}
                  ${inoxX1} ${doorTopHeight1 + 162.75}
                 `"/>

      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-11"
                :points="`
                  ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 240.89}
                  ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 240.89}
                  ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 59.92}
                  ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 59.92}
                  ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 240.89}
                 `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22,
      leafOffset: 6.4
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxX1() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxX2() {
      return this.showBg ?
        this.doorLeftWidth + this.doorWidth - this.inoxOffset :
        this.doorLeftWidth + this.doorWidth - this.leafOffset
    },
  }
}
</script>
